<template>
  <div class="m-home">
    <!-- <headCont v-if="titleText" :titleText="titleText" /> -->
    <router-view />
    <Tip
      :text="tipModal.text"
      :ico="tipModal.ico"
      :showBtns="tipModal.showBtns"
      v-if="tipModal.show"
      :fun="tipModal.fun"
      :confirmFun="tipModal.confirmFun"
      :confirmBtnText="tipModal.confirmBtnText"
      :cancelFun="tipModal.cancelFun"
      :cancelBtnText="tipModal.cancelBtnText"
    />
    <TabBar v-if="footState" />
  </div>
</template>
<script>
import Tip from "@/views/common/tip.vue";
// import headCont from "@/components/headCont.vue";
import TabBar from "@/views/common/tabBar.vue";
import { mapState } from "vuex";
export default {
  name: "m-home",
  components: {
    Tip,
    // headCont,
     TabBar,
  },
  computed: {
    ...mapState(["tipModal"]),
  },
  watch: {
    $route: {
      handler(newVal) {
        const {headState,title,footState} = newVal.meta;
        this.headState = headState ;
        this.titleText = title ;
        this.footState = footState?false:true
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        // this.CloseMLoginModal()
      },
      immediate: true,
    },
  },
  data() {
    return {
      titleText:'首页',
      footState:true,
    };
  },
}
</script>
<style lang="scss" scoped>
//页面灰背景
::v-deep .page-grey-bg {
  background-color: #f9f9f9;
}
//可视区高
::v-deep .visual-area-height {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  overflow-y: auto;
}
//tab
::v-deep .tab-titles{
  display: flex;
  padding: 40px 0 26px;
}
::v-deep .tab-title{
  padding-bottom: 20px;
  text-align: center;
  font-size: 28px;
  color: #666;
}
::v-deep .tab-title.active{
  color: #D62929;
  position: relative;
}
::v-deep .tab-title.active::after{
  content: '';
  width: 64px;
  height: 6px;
  background: #D62929;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;
}
/* 图片上传 */
::v-deep .imgGroup {
  width: 168px;
  height: 168px;
  background: #F9F9F9;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  .file-input{
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    z-index: 2;
  }
  .del {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: -36px;
    right: -36px;
    z-index: 1;
  }
  .del-img{
    width: 25px;
    height: 25px;
    margin: 39px 0 0 10px;
  }
  .upDefaultImg {
    display: block;
    width: 76px;
    height: 76px;
    margin: 28px auto 12px;
  }
  .upLoadImg {
    display: block;
    width: 100%;
    height: 100%;
  }
  .upLoadText {
    font-size: 20px;
    font-weight: 400;
    color: #999;
    text-align: center;
  }
}

</style>