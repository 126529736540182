import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

//管理vuex
export default new Vuex.Store({
	state: {
		//m提示弹窗
		tipModal: {
			show: false,
			text: '',
			showBtns: false,
			fun: null,
			cancelFun: null,
			confirmFun: null,
			subBtnText: '',
			cancelBtnText: '',
			ico: ''
		},
		userInfo: ''
	},
	mutations: {
		ShowTipModal(state, data){
			state.tipModal.show = true;
			state.tipModal.text = data.text;
			state.tipModal.showBtns = data.showBtns ? data.showBtns : false;
			state.tipModal.fun = data.fun?data.fun:null;
			state.tipModal.confirmFun = data.confirmFun?data.confirmFun:null;
			state.tipModal.confirmBtnText = data.confirmBtnText?data.confirmBtnText:'确定';
			state.tipModal.cancelFun = data.cancelFun?data.cancelFun:null;
			state.tipModal.cancelBtnText = data.cancelBtnText?data.cancelBtnText:'取消';
			state.tipModal.ico = data.ico?data.ico:'fail';
		},
		//pc
		CloseTipModal(state){
			state.tipModal.show = false;
			state.tipModal.text = '';
		},
		ShowLoginModal(state){
			state.loginModal.show = true;
		},
		//获取用户信息
		GetUserInfo(state){
			if(localStorage.getItem('userInfo')){
				state.userInfo = JSON.parse(localStorage.getItem('userInfo'));
			}else{
				state.userInfo = JSON.parse(localStorage.getItem('user'));
			}
		},
		//清空用户信息
		ClearUserInfo(state){
			localStorage.removeItem('userInfo');
			localStorage.removeItem('terminal');
			state.userInfo = '';
		}
	}
})