<template>
  <!-- 登录弹窗 -->
  <div class="tip" :class="{'hasBtn': showBtns}">
    <div class="tip-content" :class="ico">
      <div class="tip-text">{{text}}</div>
      <div class="btns" v-if="showBtns">
        <button class="button cancel" @click="cancelBtn">{{cancelBtnText}}</button>
        <button class="button confirm" @click="confirmBtn">{{confirmBtnText}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: 'tipModal',
  props: {
    text: String,
    showBtns: {
      type: Boolean,
      default: false
    },
    showTime: {
      type: Number,
      default: 2000
    },
    ico: {
      type: String,
      default: 'fail'
    },
    fun: Function,
    confirmFun: Function,
    cancelFun: Function,
    confirmBtnText: {
      type: String,
      default: '确定'
    },
    cancelBtnText: {
      type: String,
      default: '取消'
    }
  },
  mounted(){
    if(!this.showBtns){
      let _this = this;
      setTimeout(() => {
        _this.CloseTipModal();
        if(_this.fun){
          _this.fun();
        }
      }, _this.showTime);
    }
  },
  methods: {
    ...mapMutations(['CloseTipModal']), //tip弹窗
    //取消按钮
    cancelBtn(){
      this.cancelFun ? this.cancelFun() : this.CloseTipModal();
    },
    confirmBtn(){
      this.CloseTipModal();
      this.confirmFun();
    }
  }
}
</script>

<style scoped lang="scss">
.tip{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  .tip-content{
    max-width: 90%;
    padding: 20px 60px 20px 120px;
    border-radius: 5px;
    &.fail{
      background: url(~@/assets/exclamation-red.png) no-repeat 30px center rgba(0, 0, 0, 0.4);
      background-size: 40px;
    }
    &.success{
      background: url(~@/assets/tick2.png) no-repeat 30px center rgba(0, 0, 0, 0.4);
      background-size: 40px;
    }
  }
  .tip-text{
    font-size: 28px;
    color: #fff;
    text-align: center;
    line-height: 1.4;
  }
  &.hasBtn{
    background: rgba(0, 0, 0, 0.4);
    .tip-content{
      width: 640px;
      padding: 0;
      background: #fff;
      .tip-text{
        padding: 64px 48px;
        font-weight: 600;
        font-size: 32px;
        color: #000;
      }
    }
    .btns{
      display: flex;
      justify-content: center;
      border-top: 1px solid #E7E7E7;
      .button{
        padding: 0;
        width: 50%;
        height: 112px;
        background-color: #fff;
        font-size: 32px;
        &.confirm{
          color: #2F7AEA;
          border-left: 1px solid #E7E7E7;
        }
      }
    }
  }
}
</style>
