import Axios from 'axios'
//import Store from '@/store'
import { ApiUrl } from '@/utils/util'

// let auth = '';
//添加请求拦截器        跟这个 是同时进行的
Axios.interceptors.request.use(config => { //在发送请求之前执行方法
	//判断是否登录
	let userInfo = JSON.parse(localStorage.getItem('userInfo'));
	if(userInfo){
		config.headers['token'] = userInfo.token;
	}
	return config;
}, error => { //请求错误时执行方法
	return Promise.reject(error)
})

//添加响应拦截器
Axios.interceptors.response.use(response => { //响应数据执行方法
	return response.data
}, error => {
	if(error.response.data.code==401){
		localStorage.clear();
		location.href = '#/login';
	}
	return error.data
});

Axios.defaults.baseURL = ApiUrl + "/api";

export default Axios;