export const ApiUrl = 'https://rslxyapi.kjcxchina.com';

// 验证手机号
export const IsRegular = (data) => {
    return /^1[3456789]\d{9}$/.test(data)
}
// 验证身份证号
export const VerificationCard = (data) => {
    return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(data)
}
//判断是否为微信浏览器
export const IsWeiXin = () => {
    const ua = navigator.userAgent.toLowerCase();
    const isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {return true}else{return false}
}
//判断是否为windows下的微信浏览器
export const IsWindowsWeiXin = () => {
    const ua = navigator.userAgent.toLowerCase();
    const isWindowsWeiXin = ua.indexOf('windowswechat') != -1;
    if (isWindowsWeiXin) {return true}else{return false}
}
//判断是否为IOS
export const IsIos = () => {
    const ua = navigator.userAgent;
    const isIos = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isIos) {return true}else{return false}
}

//手机号/邮箱脱敏
export const Desensitization = (data) => {
    if(!data){
        return '';
    }else if(data.includes('@')){
        let email = data.split('@');
        if(email[0].length>4){
            return email[0].substring(0, email[0].length-3) + '*****' + email[1];
        }else{
            return '*****' + email[1];
        }
    }else if(data!=''){
        return data.slice(0, 3) + "****" + data.slice(data.length - 4);
    }
}
